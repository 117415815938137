<!--
 * @Author: filename
 * @Description: 高才申请人信息 - 工作经历编辑
-->
<template>
    <div class="user-container common-part">
        <el-form
            ref="form"
            :model="form"
            :rules="rules"
            label-width="140px"
            class="demo-ruleForm"
        >
            <el-row :gutter="10">
                <!-- 高才A类 - 收入种类 -->
                <el-col :span="24" v-if="Number(applicationType) === 1">
                    <el-form-item label="收入种类：" prop="type_of_income">
                        <el-select
                            v-model="form.type_of_income"
                            placeholder="请选择"
                            clearable
                            multiple
                            style="width: 320px;"
                        >
                            <el-option
                                v-for="item in CONFIG.INCOMING_TYPE"
                                :key="item.value"
                                :label="item.name"
                                :value="item.value"
                            />
                        </el-select>
                    </el-form-item>
                </el-col>
                <el-col :span="12">
                    <el-form-item label="用人单位：" prop="company_name">
                        <el-input
                            v-model="form.company_name"
                            placeholder="请输入"
                            clearable
                            auto-complete="off"
                            maxlength="50"
                        />
                    </el-form-item>
                </el-col>
                <el-col :span="12">
                    <el-form-item label="职业：" prop="career">
                        <el-input
                            v-model="form.career"
                            placeholder="请输入"
                            clearable
                            auto-complete="off"
                            maxlength="50"
                        />
                    </el-form-item>
                </el-col>
                <el-col :span="12">
                    <el-form-item label="界别：" prop="company_type">
                        <el-select
                            v-model="form.company_type"
                            placeholder="请选择"
                            clearable
                        >
                            <el-option
                                v-for="item in CONFIG.BOUNDARY_TYPE"
                                :label="item.name"
                                :value="item.value"
                                :key="item.value"
                            />
                        </el-select>
                    </el-form-item>
                </el-col>
                <el-col :span="12">
                    <el-form-item
                        v-if="form.company_type === '其它 （请注明）'"
                        label="其它 （请注明）："
                        prop="company_type_other"
                    >
                        <el-input
                            v-model="form.company_type_other"
                            placeholder="请输入"
                            clearable
                            auto-complete="off"
                            maxlength="50"
                        />
                    </el-form-item>
                </el-col>
                <el-col :span="12">
                    <el-form-item label="入职年月：" prop="entry_time">
                        <el-date-picker
                            v-model="form.entry_time"
                            type="month"
                            value-format="yyyy-MM"
                            placeholder="请选择">
                        </el-date-picker>
                    </el-form-item>
                </el-col>
                <el-col :span="12">
                    <el-form-item label="离职年月：" :prop="form.work_to_now != 1 ? 'departure_time' : ''">
                        <el-row>
                            <el-col v-if="form.work_to_now != 1" :span="17">
                                <el-date-picker
                                    v-model="form.departure_time"
                                    type="month"
                                    value-format="yyyy-MM"
                                    placeholder="请选择">
                                </el-date-picker>
                            </el-col>
                            <el-col :span="5">
                                <el-checkbox
                                    v-model="form.work_to_now"
                                    :style="{ 'margin-left': form.work_to_now == 0 ? '20px' : '0' }"
                                    label="至今"
                                    :true-label="1"
                                    :false-label="0"
                                    @change="endTimeNow"
                                >
                                    至今
                                </el-checkbox>
                            </el-col>
                        </el-row>
                    </el-form-item>
                </el-col>
                <el-col :span="24">
                    <el-form-item label="发薪单位地点：" prop="entry_time">
                        <CurrentAddress
                            ref="CurrentAddress"
                            v-model="form.payroll_unit_location"
                            :isDetails="false"
                            :isLiveOversea="false"
                        />
                    </el-form-item>
                </el-col>
                <el-col :span="24">
                    <el-form-item label-width="200px" label="工作证明是否可以提供?" prop="has_work_certificate">
                        <el-radio-group v-model="form.has_work_certificate">
                            <el-radio :label="1">是</el-radio>
                            <el-radio :label="2">否</el-radio>
                        </el-radio-group>
                    </el-form-item>
                </el-col>
            </el-row>
        </el-form>
    </div>
</template>

<script>
import { addOrderWork, updateOrderWork } from "../../../../../api/highTalent"
import CONFIG from "../../config"
export default {
    props: {
        currentItem: {
            type: Object,
            default() {
                return {}
            },
        },
        applicationType: {
            type: Number | String,
            default:() => ''
        },
        loading: {
            type: Boolean,
            default: false,
        },
    },
    created() {
        const info = JSON.parse(JSON.stringify(this.currentItem))
        if (!info.payroll_unit_location) {
            info.payroll_unit_location = {
                area: [],
                country: '',
                details: '',
                foreign: '',
            }
        } else {
            info.payroll_unit_location = info.payroll_unit_location
        }

        if (!info.has_work_certificate) {
            info.has_work_certificate = ''
        }
        this.form = info
    },
    data() {
        return {
            CONFIG,
            rules: {
                company_name: [{required: true, message: "请输入", trigger: "blur"}],
                career: [{required: true, message: "请输入", trigger: "blur"}],
                company_type: [{required: true, message: "请选择", trigger: "change"}],
                company_type_other: [{required: true, message: "请输入", trigger: "blur"}],
                entry_time: [{required: true, message: "请选择", trigger: "change"}],
                departure_time: [{required: true, message: "请选择", trigger: "change"}],
                has_work_certificate: [{required: true, message: "请选择", trigger: "change"}],
                type_of_income: [{required: true, message: "请选择", trigger: "change"}],
            },
            form: {}
        }
    },
    methods: {
        /**
         * @description 校验
         */
         async checkInfo() {
            try {
                await this.$refs.form.validate()
                this.saveInfo()
            } catch (error) {
                this.$utils.scrollIntoError(this)
            }
        },
        /**
         * @description 保存
         */
        async saveInfo() {
            try {
                this.$emit("update:loading", true)
                if (this.form.id === 0) delete this.form.id
                const fn = this.form.id ? updateOrderWork : addOrderWork
                const { code, data } = await fn(this.form)
                if (code === 200) {
                    this.$message.success("保存成功")
                    // this.form.isEdit = false
                    await this.$utils.saveHighTalentStatus(
                        this.$route.params.id
                    )
                }
            } finally {
                this.$emit("update:loading", false)
            }
        },
        /**
         * @description 至今选项
         */
        endTimeNow(e) {
            if (e) {
                this.form.departure_time = ''
            }
        },
    },
};
</script>

<style lang="less" scoped>
.certificates_info {
    margin: 4px 0 0 0;
    font-size: 11px;
    line-height: 11px;
    color: #606266;
}
.declaration_radio {
    .el-radio {
        margin: 8px 0;
    }
}
</style>
