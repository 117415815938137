<!--
 * @Author: filename
 * @Description: 个人信息表 - 资产
-->
<template>
    <!-- 回显信息 -->
    <div ref="assetsDetail" class="details-module common-part">
        <el-row :gutter="15">
            <template v-if="currentItem.application_type === 2 || currentItem.application_type === 3">
                <el-col :span="24">
                    <span style="min-width: 50px;">你在毕业后是否拥有工作经验：</span>
                    <span>{{ isNoFilter(currentItem.has_work_experience) }}</span>
                </el-col>
                <el-col :span="24">
                    <span style="min-width: 50px;">你在毕业后是否拥有至少3年工作经验：</span>
                    <span>{{ isNoFilter(currentItem.has_three_work_experience) }}</span>
                </el-col>
                <el-col :span="24">
                    <span style="min-width: 50px;">承上题，有关的工作经验是否再过去五年内积累：</span>
                    <span>{{ isNoFilter(currentItem.has_five_work_experience) }}</span>
                </el-col>
            </template>
            <el-col :span="24">
                <span style="min-width: 50px;">在职公司是否可以提供赴港同意书：</span>
                <span style="flex: 0;">{{ isNoFilter(currentItem.has_company_consent) }}</span>
                <span v-if="currentItem.has_company_consent === 1" style="margin-left: 20px;">{{ currentItem.work_company_name }}</span>
            </el-col>
            <el-col v-if="currentItem.has_company_consent === 2" :span="24">
                <span style="min-width: 50px;">人才中心是否可以提供赴港同意书：</span>
                <span>{{ isNoFilter(currentItem.has_center_consent) }}</span>
            </el-col>
        </el-row>
    </div>
</template>

<script>
export default {
    props: {
        currentItem: {
            type: Object,
            default() {
                return {}
            },
        },
    },
    data() {
        return {

        }
    },
    methods: {
        isNoFilter(val) {
            switch (val) {
                case 1:
                    return '是'
                case 2:
                    return '否'
                default:
                    return '-'
            }
        }
    }
};
</script>

<style lang="less" scoped>
.details-list .details-module .el-row .el-col > span:first-of-type {
    min-width: 30px;
}
</style>
