<!--
 * @Author: filename
 * @Description: 高才申请人信息 - 其他
-->
<template>
    <div class="user-container common-part">
        <el-form
            ref="form"
            :model="form"
            label-width="80px"
        >

            <el-row :gutter="10">
                <template v-if="currentItem.application_type === 2 || currentItem.application_type === 3">
                    <el-col :span="24">
                        <el-form-item
                            label-width="350px"
                            label="你在毕业后是否拥有工作经验："
                            prop="has_work_experience"
                            :rules="{ required: true, message: '请选择', trigger: 'change' }"
                        >
                            <el-radio-group v-model="form.has_work_experience">
                                <el-radio :label="1">是</el-radio>
                                <el-radio :label="2">否</el-radio>
                            </el-radio-group>
                        </el-form-item>
                    </el-col>
                    <el-col :span="24">
                        <el-form-item
                            label-width="350px"
                            label="你在毕业后是否拥有至少3年工作经验："
                            prop="has_three_work_experience"
                            :rules="{ required: true, message: '请选择', trigger: 'change' }"
                        >
                            <el-radio-group v-model="form.has_three_work_experience" @change="handleChange">
                                <el-radio :label="1">是</el-radio>
                                <el-radio :label="2">否</el-radio>
                            </el-radio-group>
                        </el-form-item>
                    </el-col>
                    <el-col :span="24">
                        <el-form-item
                            label-width="350px"
                            label="承上题，有关的工作经验是否再过去五年内积累："
                            prop="has_five_work_experience"
                            :rules="{ required: true, message: '请选择', trigger: 'change' }"
                        >
                            <el-radio-group v-model="form.has_five_work_experience">
                                <el-radio :label="1">是</el-radio>
                                <el-radio :label="2">否</el-radio>
                            </el-radio-group>
                        </el-form-item>
                    </el-col>
                </template>
                <el-col :span="24">
                    <div style="display: flex;">
                        <el-form-item
                            label-width="350px"
                            label="在职公司是否可以提供赴港同意书："
                            prop="has_company_consent"
                            :rules="{ required: true, message: '请选择', trigger: 'change' }"
                        >
                            <el-radio-group v-model="form.has_company_consent">
                                <el-radio :label="1">是</el-radio>
                                <el-radio :label="2">否</el-radio>
                            </el-radio-group>
                        </el-form-item>
                        <el-form-item
                            v-if="form.has_company_consent === 1"
                            label-width="0px"
                            prop="work_id"
                            :rules="{ required: true, message: '请选择', trigger: 'change' }"
                        >
                            <el-select
                                v-model="form.work_id"
                                placeholder="请选择公司"
                                clearable
                                style="width: 100%;"
                                @visible-change="getCompany"
                                :loading="isLoading"
                            >
                                <el-option
                                    v-for="item in workCompanyList"
                                    :label="item.company_name"
                                    :value="item.id"
                                    :key="item.id"
                                />
                            </el-select>
                        </el-form-item>
                    </div>
                </el-col>
                <el-col v-if="form.has_company_consent === 2" :span="24">
                    <el-form-item
                        label-width="350px"
                        label="人才中心是否可以提供赴港同意书："
                        prop="has_center_consent"
                        :rules="{ required: true, message: '请选择', trigger: 'change' }"
                    >
                        <el-radio-group v-model="form.has_center_consent">
                            <el-radio :label="1">是</el-radio>
                            <el-radio :label="2">否</el-radio>
                        </el-radio-group>
                    </el-form-item>
                </el-col>
            </el-row>
        </el-form>
    </div>
</template>

<script>
import { saveGcWorkOther, getWorkCompany } from "../../../../../api/highTalent"
export default {
    props: {
        currentItem: {
            type: Object,
            default() {
                return {}
            },
        },
        loading: {
            type: Boolean,
            default: false,
        },
    },
    created() {
        this.getCompany(true)
        if (this.currentItem.work_id === 0) {
            this.currentItem.work_id = ''
        }
        this.form = JSON.parse(JSON.stringify(this.currentItem))
    },
    data() {
        return {
            form: {},
            isLoading: false,
            workCompanyList: [],
        }
    },
    methods: {
        /**
         * @description 校验保存
         */
        async checkInfo() {
            try {
                await this.$refs.form.validate()
                this.saveInfo()
            } catch (error) {
                this.$utils.scrollIntoError(this)
            }
        },
        // 保存
        async saveInfo() {
            try {
                this.$emit("update:loading", true)
                const { code } = await saveGcWorkOther({ ...this.form, order_id: this.$route.params.id })
                if (code === 200) {
                    this.$message.success("保存成功")
                    this.$emit('update')
                    await this.$utils.saveHighTalentStatus(
                        this.$route.params.id
                    )
                }
            } finally {
                this.$emit("update:loading", false)
            }
        },
        // 获取工作经历的公司
        async getCompany(isShow) {
            if (!isShow) {
                return
            }
            try {
                this.isLoading = true
                const { data, code } = await getWorkCompany({ order_id: this.$route.query.order_id })
                if (code === 200) {
                    this.workCompanyList = data
                }
            } finally {
                this.isLoading = false
            }
        },
        // 切换3年工作经验
        handleChange(val) {
            this.form.has_five_work_experience = val
        }
    },
};
</script>

<style lang="less" scoped>

</style>
