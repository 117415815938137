<!--
 * @Author: Geroge
 * @Description: 高才申请人信息
-->
<template>
    <div class="user-container common-part">
        <!-- 主申请人信息 -->
        <el-form
            ref="currentItem"
            :model="currentItem"
            :rules="rules"
            label-width="150px"
            class="demo-ruleForm"
        >
            <el-row :gutter="10">
                <el-col :span="12">
                    <el-form-item label="子女姓名：" prop="subset_name">
                        <el-input
                            v-model="currentItem.subset_name"
                            placeholder="姓名请与身份证一致"
                            clearable
                            auto-complete="off"
                            maxlength="50"
                        />
                    </el-form-item>
                </el-col>
                <el-col :span="12">
                    <el-form-item label="曾用名：" prop="used_name">
                        <el-input
                            v-model="currentItem.used_name"
                            placeholder="请与户口本保持一致"
                            clearable
                            auto-complete="off"
                            maxlength="50"
                        />
                    </el-form-item>
                </el-col>
                <el-col :span="12">
                    <el-form-item label="婚前姓氏：">
                        <el-input
                            v-model="currentItem.surname"
                            placeholder="如有，可填"
                            clearable
                            auto-complete="off"
                            maxlength="50"
                        />
                    </el-form-item>
                </el-col>
                <el-col :span="12">
                    <el-form-item
                        label="姓名拼音："
                        prop="subset_name_pinyin.family_name"
                        class="pinyin"
                    >
                        <el-input
                            v-model="currentItem.subset_name_pinyin.family_name"
                            clearable
                            auto-complete="off"
                            maxlength="50"
                            placeholder="请输入姓氏拼音"
                        />
                        <el-form-item
                            prop="subset_name_pinyin.given_name"
                            class="add-fields"
                        >
                            <el-input
                                v-model="
                                    currentItem.subset_name_pinyin.given_name
                                "
                                clearable
                                auto-complete="off"
                                maxlength="50"
                                placeholder="请输入名称拼音"
                            />
                        </el-form-item>
                    </el-form-item>
                </el-col>
                <el-col :span="12">
                    <el-form-item label="性别：" prop="sex">
                        <el-select
                            v-model="currentItem.sex"
                            placeholder="请选择"
                            clearable
                        >
                            <el-option
                                v-for="item in CONFIG.SEX_TYPE"
                                :label="item.name"
                                :value="item.value"
                                :key="item.value"
                            />
                        </el-select>
                    </el-form-item>
                </el-col>
                <el-col :span="12">
                    <el-form-item label="出生日期：" prop="birthday">
                        <el-date-picker
                            v-model="currentItem.birthday"
                            value-format="yyyy-MM-dd"
                            type="date"
                            placeholder="请选择"
                        />
                    </el-form-item>
                </el-col>
                <el-col :span="12">
                    <el-form-item label="国籍/地区：" prop="nationality">
                        <CountrySelect v-model="currentItem.nationality" />
                    </el-form-item>
                </el-col>
                <el-col :span="12">
                    <el-form-item
                        label="出生城市："
                        :prop="`birth_place_aboard`"
                        class="birth_city"
                    >
                        <el-radio-group
                            v-model="currentItem.birth_place_aboard"
                            @change="changeBirthCountry"
                        >
                            <el-radio :label="0">国内</el-radio>
                            <el-radio :label="1">国外</el-radio>
                        </el-radio-group>
                        <el-form-item
                            v-if="currentItem.birth_place_aboard === 0"
                            prop="birth_place.details"
                            class="add-fields"
                        >
                            <el-input
                                v-model="currentItem.birth_place.details"
                                maxlength="100"
                                placeholder="请输入详细地址"
                                clearable
                                auto-complete="off"
                            />
                        </el-form-item>
                        <el-form-item
                            v-if="currentItem.birth_place_aboard === 1"
                            prop="birth_place.foreign"
                            class="add-fields"
                        >
                            <el-input
                                v-model="currentItem.birth_place.foreign"
                                maxlength="100"
                                placeholder="请输入"
                                clearable
                                auto-complete="off"
                            />
                        </el-form-item>
                    </el-form-item>
                </el-col>
                <el-col :span="12">
                    <el-form-item label="婚姻状况：" prop="married">
                        <el-select
                            v-model="currentItem.married"
                            placeholder="请选择"
                            clearable
                        >
                            <el-option
                                v-for="item in CONFIG.MARRY_TYPE"
                                :key="item.value"
                                :label="item.name"
                                :value="item.value"
                            />
                        </el-select>
                    </el-form-item>
                </el-col>
                <el-col :span="12">
                    <el-form-item
                        label="是否与主申请人同住："
                        prop="nows"
                        label-width="180px"
                    >
                        <el-radio-group v-model="currentItem.nows">
                            <el-radio :label="1">是</el-radio>
                            <el-radio :label="0">否</el-radio>
                        </el-radio-group>
                    </el-form-item>
                </el-col>
                <el-col
                    :span="24"
                    v-if="currentItem.nows === 0">
                    <el-form-item
                        label="现居住地址："
                        prop="address"
                    >
                        <CurrentAddress ref="CurrentAddress" v-model="currentItem.address" :is_live_oversea_year.sync="currentItem.is_live_oversea_year"/>
                    </el-form-item>
                </el-col>
                <el-col :span="12">
                    <el-form-item
                        label="是否办理过香港身份证："
                        prop="has_hk_id.has"
                        label-width="180px"
                    >
                        <el-radio-group
                            v-model="currentItem.has_hk_id.has"
                            @change="changeHKHas"
                        >
                            <el-radio label="Y">是</el-radio>
                            <el-radio label="N">否</el-radio>
                        </el-radio-group>
                    </el-form-item>
                </el-col>
                <el-col :span="12">
                    <el-form-item
                        v-if="currentItem.has_hk_id.has === 'Y'"
                        label="香港身份证号码："
                        prop="has_hk_id.info"
                    >
                        <HkIdCardInput v-model="currentItem.has_hk_id.info" suffix-required></HkIdCardInput>
                    </el-form-item>
                </el-col>
                <el-col :span="12">
                    <el-form-item
                        label="证件类型："
                    >
                        <el-select
                            v-model="currentItem.certificates.passport.type"
                            placeholder="请选择"
                            @change="changeCertificateType"
                            clearable
                        >
                            <el-option
                                v-for="item in CONFIG.CERTIFICATE_TYPE"
                                :key="item.value"
                                :label="item.name"
                                :value="item.value"
                            />
                        </el-select>
                        <p class="certificates_info">
                            签约前12个月常居国内请勾选港澳行证，常居海外则勾选护照。
                        </p>
                    </el-form-item>
                </el-col>
                <el-col :span="12">
                    <el-form-item
                        label="证件号码："
                        :style="{
                            marginBottom:
                                currentItem.has_hk_id.has === 'Y'
                                    ? '34px'
                                    : '18px',
                        }"
                    >
                        <el-input
                            v-model="currentItem.certificates.passport.number"
                            clearable
                            maxlength="50"
                            placeholder="请输入证件号码"
                        />
                    </el-form-item>
                </el-col>
                <el-col :span="12">
                    <el-form-item
                        label="签发地："
                    >
                        <el-input
                            v-model="currentItem.certificates.passport.issue_at"
                            clearable
                            maxlength="50"
                            placeholder="请输入签发地"
                        />
                    </el-form-item>
                </el-col>
                <el-col :span="12">
                    <el-form-item
                        label="签发日期："
                    >
                        <el-date-picker
                            v-model="
                                currentItem.certificates.passport
                                    .issue_date_start_at
                            "
                            value-format="yyyy-MM-dd"
                            type="date"
                            placeholder="请选择"
                        />
                    </el-form-item>
                </el-col>
                <el-col :span="12">
                    <el-form-item
                        label="届满日期："
                    >
                        <el-date-picker
                            v-model="
                                currentItem.certificates.passport
                                    .issue_date_end_at
                            "
                            value-format="yyyy-MM-dd"
                            type="date"
                            placeholder="请选择"
                        />
                    </el-form-item>
                </el-col>
                <el-col :span="12">
                    <el-form-item
                        label="是否前次婚姻的子女："
                        prop="step_child"
                        label-width="180px"
                    >
                        <el-radio-group v-model="currentItem.step_child">
                            <el-radio :label="1">是</el-radio>
                            <el-radio :label="0">否</el-radio>
                        </el-radio-group>
                    </el-form-item>
                </el-col>
                <template v-if="isApplicantShow">
                    <el-col :span="24">
                        <el-form-item class="form-item-title__w355" label="申请人是否拥有现时定居国家/地区的永久居留身份：" prop="have_permanent_residency">
                            <el-radio-group
                                v-model="currentItem.have_permanent_residency"
                            >
                                <el-radio :label="1">是</el-radio>
                                <el-radio :label="0">否</el-radio>
                            </el-radio-group>
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item class="form-item-title__w200" label="在定居国家/地区居留时间：" prop="residence_time">
                            <el-date-picker
                                v-model="currentItem.residence_time"
                                value-format="yyyy-MM"
                                type="month"
                                placeholder="请选择"
                                style="width: 250px"
                            />
                        </el-form-item>
                    </el-col>
                </template>
                <el-col :span="12">
                    <el-form-item label="在定居国家/地区：" prop="residence_address.country">
                        <CountrySelect v-model="currentItem.residence_address.country" />
                    </el-form-item>
                </el-col>
                <el-col :span="12">
                    <el-form-item label="与申请人关系：" prop="relation_desc">
                        <el-input
                            v-model="currentItem.relation_desc"
                            placeholder="请输入"
                            clearable
                            auto-complete="off"
                            maxlength="50"
                        />
                    </el-form-item>
                </el-col>
                <el-col :span="24">
                    <el-form-item label="相关说明1：" prop="declaration_first">
                        <el-radio-group
                            class="declaration_radio"
                            v-model="currentItem.declaration_first"
                            @change="
                                (val) =>
                                    changeDeclaration(val, 'declaration_first')
                            "
                        >
                            <el-radio :label="0"
                                >本人未曾被拒绝签发签证或入境香港，同时也没有被遣返、遣送或要求离开香港的情况</el-radio
                            >
                            <el-radio :label="1"
                                >本人曾经遭到过拒签或被拒绝入境香港的情况，具体日期和详细情况如下</el-radio
                            >
                        </el-radio-group>
                    </el-form-item>
                </el-col>
                <el-col :span="24">
                    <el-form-item
                        label=""
                        prop="declaration_first_desc"
                        v-if="currentItem.declaration_first === 1"
                    >
                        <el-input
                            type="textarea"
                            :rows="2"
                            maxlength="500"
                            placeholder="请输入具体日期和详细情况"
                            v-model="currentItem.declaration_first_desc"
                        >
                        </el-input>
                    </el-form-item>
                </el-col>
                <el-col :span="24">
                    <el-form-item label="相关说明2：" prop="declaration_second">
                        <el-radio-group
                            class="declaration_radio"
                            v-model="currentItem.declaration_second"
                            @change="
                                (val) =>
                                    changeDeclaration(val, 'declaration_second')
                            "
                        >
                            <el-radio :label="0"
                                >本人从未因任何罪行或违法行为在香港或其他地方被定罪</el-radio
                            >
                            <el-radio :label="1"
                                >本人曾因任何罪行或违法行为在香港或其他地方被定罪。有关日期和详细情况如下</el-radio
                            >
                        </el-radio-group>
                    </el-form-item>
                </el-col>
                <el-col :span="24">
                    <el-form-item
                        label=""
                        prop="declaration_second_desc"
                        v-if="currentItem.declaration_second === 1"
                    >
                        <el-input
                            type="textarea"
                            :rows="2"
                            maxlength="500"
                            placeholder="请输入具体日期和详细情况"
                            v-model="currentItem.declaration_second_desc"
                        >
                        </el-input>
                    </el-form-item>
                </el-col>
                <el-col :span="24">
                    <el-form-item label-width="280px" label="主申请人和现配偶是否拥有孩子抚养权：" prop="custody">
                        <el-radio-group
                            v-model="currentItem.custody"
                        >
                            <el-radio :label="1">是</el-radio>
                            <el-radio :label="2">否</el-radio>
                        </el-radio-group>
                    </el-form-item>
                </el-col>
            </el-row>
        </el-form>
    </div>
</template>

<script>
import HkIdCardInput from '@/components/HkIdCardInput.vue'
import { saveHighTalentInfo } from "../../../../../../../api/highTalent";
import CONFIG from "../../../../config";
export default {
    components: { HkIdCardInput },
    props: {
        // 主申人信息
        currentItem: {
            type: Object,
            default() {
                return {};
            },
        },
        required: {
            type: Boolean,
            default: true,
        },
        loading: {
            type: Boolean,
            default: false,
        },
    },
    computed: {
        isApplicantShow() {
            const { nationality, address: { country, area } } = this.currentItem
            const specialAreas = ['香港特别行政区', '澳门特别行政区', '台湾']
            return nationality === '中国-China' && (country !== '中国-China' || (area.length > 0 && specialAreas.includes(area[0])))
        }
    },
    created() {
        for(let key in this.currentItem) {
            if (this.currentItem[key] == 100) {
                this.currentItem[key] = ''
            }
        }
        if(!this.currentItem.residence_address || this.currentItem.residence_address.length === 0) {
            this.currentItem.residence_address = {
                area: [],
                country: '',
                foreign: '',
                details: '',
            }
        }
    },
    data() {
        return {
            CONFIG,
            rules: {
                subset_name: [
                    {
                        required: this.required,
                        message: "请输入姓名",
                        trigger: "blur",
                    },
                ],
                "subset_name_pinyin.family_name": [
                    {
                        required: this.required,
                        message: "请输入姓氏拼音",
                        trigger: "blur",
                    },
                ],
                "subset_name_pinyin.given_name": [
                    {
                        required: this.required,
                        message: "请输入名称拼音",
                        trigger: "blur",
                    },
                ],
                birthday: [
                    {
                        required: this.required,
                        message: "请选择出生日期",
                        trigger: "change",
                    },
                ],
                birth_place_aboard: [
                    {
                        required: this.required,
                        message: "请选择国内/国外",
                        trigger: "change",
                    },
                ],
                "birth_place.details": [
                    {
                        required: this.required,
                        message: "请输入详细地址",
                        trigger: "blur",
                    },
                ],
                "birth_place.foreign": [
                    {
                        required: this.required,
                        message: "请输入地址",
                        trigger: "blur",
                    },
                ],
                sex: [
                    {
                        required: this.required,
                        message: "请选择性别",
                        trigger: "change",
                    },
                ],
                married: [
                    {
                        required: this.required,
                        message: "请选择婚姻状况",
                        trigger: "change",
                    },
                ],
                nationality: [
                    {
                        required: this.required,
                        message: "请输入国籍/地区",
                        trigger: "change",
                    },
                ],
                "has_hk_id.has": [
                    {
                        required: this.required,
                        message: "请选择是否办理过香港身份证",
                        trigger: "change",
                    },
                ],
                "has_hk_id.info": [
                    {
                        required: this.required,
                        trigger: "change",
                        validator: this.$rules.validate.hkmIdSuffix,
                    },
                ],
                industry: [
                    {
                        required: this.required,
                        message: "请选择所在行业",
                        trigger: "change",
                    },
                ],
                industry_other_desc: [
                    {
                        required: this.required,
                        message: "请输入其他行业",
                        trigger: "change",
                    },
                ],
                "certificates.passport.type": [
                    {
                        required: this.required,
                        message: "请选择证件类型",
                        trigger: "change",
                    },
                ],
                "certificates.passport.issue_at": [
                    {
                        required: this.required,
                        message: "请输入签发地",
                        trigger: "change",
                    },
                ],
                "certificates.passport.issue_date_start_at": [
                    {
                        required: this.required,
                        message: "请选择签发日期",
                        trigger: "change",
                    },
                ],
                "certificates.passport.issue_date_end_at": [
                    {
                        required: this.required,
                        message: "请选择届满日期",
                        trigger: "change",
                    },
                ],
                "certificates.passport.number": [
                    {
                        required: this.required,
                        message: "请输入证件号码",
                        trigger: "change",
                    },
                ],

                declaration_first: [
                    {
                        required: this.required,
                        message: "请选择相关说明1",
                        trigger: "change",
                    },
                ],
                declaration_second: [
                    {
                        required: this.required,
                        message: "请选择相关说明2",
                        trigger: "change",
                    },
                ],
                declaration_first_desc: [
                    {
                        required: this.required,
                        message: "请输入具体日期和详细情况",
                        trigger: "change",
                    },
                ],
                declaration_second_desc: [
                    {
                        required: this.required,
                        message: "请输入具体日期和详细情况",
                        trigger: "change",
                    },
                ],
                undergraduate_end_time: [
                    {
                        required: this.required,
                        message: "请选择本科毕业年份",
                        trigger: "change",
                    },
                ],
                step_child: [
                    {
                        required: this.required,
                        message: "请选择是否是否前次婚姻的子女",
                        trigger: "change",
                    },
                ],
                address: [
                    {
                        required: this.required,
                        message: "请输入国家",
                        trigger: "change",
                    }
                ],
                nows: [
                    {
                        // this.$route.query.history  历史订单处理
                        required:  this.$route.query.history ? false : this.required,
                        message: "请选择",
                        trigger: "change",
                    }
                ],
                have_permanent_residency: [
                    {
                        required: this.required,
                        message: "请选择",
                        trigger: "change",
                    }
                ],
                residence_time: [
                    {
                        required: this.required,
                        message: "请选择",
                        trigger: "change",
                    }
                ],
                relation_desc: [
                    {
                        required: this.required,
                        message: "请输入",
                        trigger: "blur",
                    }
                ],
                'residence_address.country': [
                    {
                        required: this.required,
                        message: "请输入选择",
                        trigger: "change",
                    }
                ],
                custody: [
                    {
                        required: this.required,
                        message: "请选择",
                        trigger: "change",
                    }
                ]
            },
        };
    },
    methods: {
        /**
         * @description 清除校验
         */
        async clearValidate(props) {
            await this.$nextTick;
            this.$refs.currentItem?.clearValidate(props);
        },
        /**
         * @description 切换出生国家
         */
        changeBirthCountry(val) {
            if (val === 0) {
                this.currentItem.birth_place.foreign = "";
            } else {
                this.currentItem.birth_place.area = [];
            }
            this.clearValidate(["birth_place_area", "birth_place.foreign"]);
        },
        /**
         * @description 香港身份证切换
         */
        changeHKHas() {
            this.currentItem.has_hk_id.info = "";
            this.clearValidate("has_hk_id.info");
        },
        /**
         * @description 证件类型切换
         */
        changeCertificateType(val) {
            this.currentItem.certificates.passport.issue_at = "";
            this.currentItem.certificates.passport.issue_date_start_at = "";
            this.currentItem.certificates.passport.issue_date_end_at = "";
            this.currentItem.certificates.passport.number = "";
            this.clearValidate([
                "certificates.passport.issue_at",
                "certificates.passport.issue_date_start_at",
                "certificates.passport.issue_date_end_at",
                "certificates.passport.number",
            ]);
        },
        /**
         * @description 所在行业切换
         */
        changeIndustry() {
            this.currentItem.industry_other_desc = "";
            this.clearValidate("industry_other_desc");
        },
        /**
         * @description 所在行业切换
         */
        changeIndustry() {
            this.currentItem.industry_other_desc = "";
            this.clearValidate("industry_other_desc");
        },
        /**
         * @description 声明切换
         */
        changeDeclaration(val, type) {
            const key = type + "_desc";
            this.currentItem[key] = "";
            this.clearValidate(key);
        },
        /**
         * @description 校验当前步骤的表单
         * @param {type} 1保存 2下一步
         */
        async checkInfo() {
            // 点击的是下一步 校验成功后发送请求
            Promise.all([this.$refs.currentItem.validate()])
                .then(async () => {
                    this.submit();
                })
                .catch((err) => {
                    console.log(err);
                    this.$utils.scrollIntoError(this);
                });
        },

        async submit() {
            try {
                this.$emit("update:loading", true);
                this.currentItem.child_has_hk_id = this.currentItem.has_hk_id
                const res = await saveHighTalentInfo({
                    ...this.currentItem,
                    role: 2, // 0:主申人；1：配偶；2：子女
                    crm_token: this.$route.query.crm_token,
                });
                if (res.code === 200) {
                    this.$message.success("保存成功");
                    await this.$utils.saveHighTalentStatus(
                        this.$route.params.id
                    );
                    // this.$emit('update:currentItem', this.currentItem)
                    this.$emit("update");
                }
            } catch (error) {
                console.log(error);
            } finally {
                this.$emit("update:loading", false);
            }
        },
    },
};
</script>

<style lang="less" scoped>
.certificates_info {
    margin: 4px 0 0 0;
    font-size: 11px;
    line-height: 11px;
    color: #606266;
}
.declaration_radio {
    .el-radio {
        margin: 8px 0;
    }
}
.form-item-title__w355 {
    /deep/ .el-form-item__label {
        width: 355px !important;
    }
}
.form-item-title__w170 {
    /deep/ .el-form-item__label {
        width: 170px !important;
    }
}
.form-item-title__w200 {
    /deep/ .el-form-item__label {
        width: 200px !important;
    }
}
</style>
