<!--
 * @Author: George
 * @Description: 高才家庭成员-配偶信息
-->
<template>
    <Container title="配偶" padding="0" id="pdf-spouse" v-loading="isLoading">
        <div slot="handle" v-if="!spouse">
            <div class="handle-btn">
                <el-button @click="addSpouse(1)">新增-随行</el-button>
                <el-button @click="addSpouse(2)">新增-不随行</el-button>
            </div>
        </div>
        <template v-if="spouse">
            <div class="title-box">
                <span>
                    <el-tooltip
                        v-if="this.spouse.finish_follow == 1"
                        class="item"
                        effect="dark"
                        placement="top-start"
                    >
                        <span class="tooltip">补</span>
                        <template slot="content">
                            <div class="content">
                                <div>
                                    创建人：{{
                                        spouse.supplement_info.operator_name
                                    }}
                                </div>
                                <div>
                                    加入时间：{{
                                        spouse.supplement_info.created_at
                                    }}
                                </div>
                                <div>
                                    主合同任务：{{
                                        spouse.supplement_info.order_task_name
                                    }}
                                </div>
                            </div>
                        </template>
                    </el-tooltip>
                    配偶信息 &nbsp;&nbsp;
                    <el-switch
                        @change="followChange"
                        active-text="随行"
                        inactive-text="不随行"
                        v-model="spouse.follow"
                        id="spouse-follow"
                        :active-value="1"
                        :inactive-value="2"
                    >
                    </el-switch>
                </span>
                <div class="title-btn">
                    <el-button
                        v-if="isEdit"
                        :loading="isLoading"
                        @click="cancel"
                        >取消</el-button
                    >
                    <el-button v-if="!isEdit" type="primary" @click="editInfo"
                        >编辑</el-button
                    >
                    <el-button
                        v-if="isEdit"
                        type="primary"
                        :loading="isLoading"
                        @click="saveInfo"
                        >保存</el-button
                    >
                </div>
            </div>
            <!-- 回显信息 -->
            <SpouseDetail v-if="!isEdit" :currentItem="spouse" />
            <!-- 编辑 -->
            <EditSpouse
                ref="editSpouse"
                v-if="isEdit"
                :required="spouse.follow === 1"
                :loading.sync="isLoading"
                :currentItem.sync="spouse"
                @update="updateDetail"
            />
        </template>
        <div v-else class="no-data">暂无数据</div>
    </Container>
</template>

<script>
import SpouseDetail from "./C/SpouseDetail.vue";
import EditSpouse from "./C/EditSpouse.vue";

import {
    saveHighTalentInfo,
    initHighTalentFamily,
} from "../../../../../../api/highTalent";

export default {
    props: {
        info: {
            type: Object,
            default: () => {},
        },
    },
    components: {
        SpouseDetail,
        EditSpouse,
    },
    data() {
        return {
            isLoading: false,
            isEdit: false,
            spouse: {},
        };
    },
    watch: {
        info: {
            handler(newVal) {
                const spouse = JSON.parse(JSON.stringify(newVal || ""))
                // 为空时，初始化相关说明字段
                const fields = ['declaration_first', 'declaration_first_desc', 'declaration_second', 'declaration_second_desc']
                if (spouse) {
                    fields.forEach((key) => {
                        if (!spouse[key] && spouse[key] != 0) {
                            spouse[key] = ''
                        }
                    })
                }
                this.spouse = spouse
            },
            deep: true,
            immediate: true,
        },
    },
    methods: {
        // 配偶 type 1 随行 2不随行
        async addSpouse(type) {
            if (this.isEditChild) {
                this.$message.error("请先保存正在编辑或者新增的子女！");
                return;
            }

            this.isLoading = true;
            try {
                await initHighTalentFamily({
                    order_id: this.$route.params.id,
                    role: 1,
                    follow: type,
                });
                await this.$utils.saveHighTalentStatus(this.$route.params.id);
                this.anchorPoint();
            } finally {
                this.isLoading = false;
            }
        },
        // 是否随行
        async followChange(val) {
            try {
                this.isLoading = true;
                const res = await saveHighTalentInfo({
                    ...this.info,
                    follow: val,
                    role: 1, // 0:主申人；1：配偶；2：子女
                    crm_token: this.$route.query.crm_token,
                });
                if (res.code === 200) {
                    this.$message.success("保存成功");
                    await this.$utils.saveHighTalentStatus(
                        this.$route.params.id
                    );
                    this.isEdit = false;
                } else {
                    this.spouse.follow = val === 1 ? 2 : 1;
                }
            } catch (error) {
                this.spouse.follow = val === 1 ? 2 : 1;
                console.log(error);
            } finally {
                this.isLoading = false;
            }
        },
        // 更新
        updateDetail() {
            this.spouse = JSON.parse(JSON.stringify(this.info));

            this.isEdit = false;
        },
        // 保存
        async saveInfo() {
            console.log("====>");
            this.$refs.editSpouse?.checkInfo();
        },
        // 编辑
        editInfo() {
            this.isEdit = true;
        },
        // 取消
        cancel() {
            this.spouse = JSON.parse(JSON.stringify(this.info));
            this.isEdit = false;
        },
    },
};
</script>

<style lang="less" scoped>
.tooltip {
    display: inline-block;
    border-radius: 100%;
    background: #1296db;
    color: #fff;
    width: 20px;
    height: 20px;
    line-height: 20px;
    text-align: center;
    border: 1px #377395 solid;
    // height: 100%;
}
.content {
    padding: 3px;
    div {
        margin: 10px 0;
    }
}
</style>
