<!--
 * @Author: George
 * @Description: 高才家庭成员-子女信息
-->
<template>
    <div class="mt-20">
        <Container
            title="其他"
            padding="0"
        >
            <div class="title-box">
                <span>工作经验补充信息</span>
                <div class="title-btn">
                    <el-button
                        v-if="!isEdit"
                        type="primary"
                        @click="isEdit = true"
                        >编辑</el-button
                    >
                    <el-button v-if="isEdit" @click="cancel"
                        >取消</el-button
                    >
                    <el-button
                        v-if="isEdit"
                        type="primary"
                        @click="saveInfo"
                        >保存</el-button
                    >
                </div>
            </div>
            <!-- 回显信息 -->
            <OtherDetail
                v-if="!isEdit"
                :currentItem="otherData"
            />
            <!-- 编辑 -->
            <EditOther
                v-if="isEdit"
                ref="other"
                :currentItem.sync="otherData"
                :loading.sync="isLoading"
                @update="update"
            />
        </Container>
    </div>
</template>

<script>
import OtherDetail from "./C/OtherDetail.vue"
import EditOther from "./C/EditOther.vue"
export default {
    props: {
        info: {
            type: Object,
            default: () => {},
        },
    },
    components: {
        OtherDetail,
        EditOther,
    },
    data() {
        return {
            isLoading: false,
            otherData: {},
            isEdit: false
        };
    },
    created() {
        this.initInfo()
    },
    watch: {
        info() {
            this.initInfo()
        }
    },
    methods: {
        initInfo() {
            this.otherData = JSON.parse(JSON.stringify(this.info.other.work))
            this.otherData = {
                ...this.otherData,
                application_type: this.info.applicant.application_type
            }
            this.isEdit = false
        },
        // 取消
        cancel() {
            this.initInfo()
            this.isEdit = false
        },
        // 更新
        update() {
            this.isEdit = false
        },
        // 保存
        async saveInfo() {
            await this.$refs.other.checkInfo()
        },
    },
}
</script>

<style lang="less" scoped>
.tooltip {
    display: inline-block;
    border-radius: 100%;
    background: #1296db;
    color: #fff;
    width: 20px;
    height: 20px;
    line-height: 20px;
    text-align: center;
    border: 1px #377395 solid;
    // height: 100%;
}
.content {
    padding: 3px;
    div {
        margin: 10px 0;
    }
}
</style>
