<!--
 * @Author: filename
 * @Description: 高才申请人信息 - 资产编辑
-->
<template>
    <div class="user-container common-part">
        <el-form
            ref="form"
            :model="form"
            label-width="80px"
        >

            <el-row :gutter="10">
                <el-col :span="24">
                    <el-form-item
                        label-width="210px"
                        label="公司营业额/人民币 (近一年)："
                        prop="year_money"
                        :rules="[ { validator: yearMoney, trigger: 'blur' } ]"
                    >
                        <el-input
                            v-model="form.year_money"
                            placeholder="请输入"
                            clearable
                            auto-complete="off"
                            style="width: 245px;"
                        />
                    </el-form-item>
                </el-col>
                <el-col :span="12">
                    <el-form-item
                        label="货币："
                        prop="currency"
                        :rules="{ required: true, message: '请选择', trigger: 'change' }"
                    >
                        <el-select
                            v-model="form.currency"
                            placeholder="请选择"
                            clearable
                            style="width: 100%;"
                            disabled
                        >
                            <el-option
                                v-for="item in CONFIG.CURRENCY_TYPE"
                                :label="item.name"
                                :value="item.value"
                                :key="item.value"
                            />
                        </el-select>
                    </el-form-item>
                </el-col>
                <el-col :span="10">
                    <el-form-item
                        label="金额："
                        prop="amount_of_money"
                        :rules="[ { validator: validateMoney, trigger: 'blur' } ]"
                    >
                        <el-input
                            v-model="form.amount_of_money"
                            placeholder="请输入"
                            clearable
                            auto-complete="off"
                        />
                    </el-form-item>
                </el-col>
            </el-row>
        </el-form>
    </div>
</template>

<script>
import CONFIG from "../../config"
import { addProperty, updateProperty } from "../../../../../api/highTalent"
export default {
    props: {
        currentItem: {
            type: Object,
            default() {
                return {}
            },
        },
        loading: {
            type: Boolean,
            default: false,
        },
    },
    created() {
        const obj = { ...this.currentItem, currency: '人民币'}
        this.form = JSON.parse(JSON.stringify(obj))
    },
    data() {
        const validateMoney= (rule, value, callback) => {
            if (value === '') {
                return callback(new Error('请输入金额'))
            }
            const n = +value
            if (Number.isNaN(n)) {
                return callback(new Error('请输入有效的数字'))
            }
            if (n < 0) {
                return callback(new Error('金额不得为负数'))
            }
            callback()
        }
        const yearMoney = (rule, value, callback) => {
            if (value === '' && value !== 0) {
                return callback(new Error('请输入金额'))
            }

            // 校验正则：允许 0，正整数，和正浮点数
            const regex = /^(0(\.\d+)?|[1-9]\d*(\.\d+)?)$/

            if (!regex.test(value)) {
                return callback(new Error('请输入一个不小于 0 的金额'))
            }
            callback()
        }
        return {
            validateMoney,
            yearMoney,
            CONFIG,
            form: {}
        }
    },
    methods: {
        /**
         * @description 校验保存
         */
        async checkInfo() {
            try {
                await this.$refs.form.validate()
                this.saveInfo()
            } catch (error) {
                this.$utils.scrollIntoError(this)
            }
        },
        // 保存
        async saveInfo() {
            try {
                this.$emit("update:loading", true)
                if (this.form.id === 0) delete this.form.id
                const fn = this.form.id ? updateProperty : addProperty
                const { code, data } = await fn(this.form)
                if (code === 200) {
                    if (!this.form.id) this.form.id = data.id
                    this.$message.success("保存成功")
                    this.form.isEdit = false
                    await this.$utils.saveHighTalentStatus(
                        this.$route.params.id
                    )
                }
            } finally {
                this.$emit("update:loading", false)
            }
        },
    },
};
</script>

<style lang="less" scoped>
.form-item {
    /deep/ .el-form-item__content {
        margin-left: 0 !important;
    }
}
</style>
