<!--
 * @Author: George
 * @Description: 高才个人信息表 - 主申请人信息
-->
<template>
    <div v-loading="isLoading">
        <Container title="主申请人" padding="0" id="pdf-user-top">
            <div id="pdf-user">
                <div class="title-box">
                    <span>主申信息</span>
                    <div class="title-btn">
                        <el-button
                            v-if="!isEdit"
                            type="primary"
                            @click="isEdit = true"
                            >编辑</el-button
                        >
                        <el-button v-if="isEdit" @click="cancel"
                            >取消</el-button
                        >
                        <el-button
                            v-if="isEdit"
                            type="primary"
                            :loading="isLoading"
                            @click="saveInfo"
                            >保存</el-button
                        >
                    </div>
                </div>
                <!-- 回显信息 -->
                <UserDetail
                    class="pdf-user-detail"
                    v-if="!isEdit"
                    :currentItem="currentItem"
                />
                <!-- 编辑 -->
                <User
                    v-if="isEdit"
                    ref="user"
                    :currentItem.sync="currentItem"
                    :loading.sync="isLoading"
                    @update="updateDetail"
                />
            </div>

          <div slot="handle">
              <el-button v-if="[2, 3].includes(currentItem.application_type)" @click="toDwpAddEducationCertification">提交学位认证需求</el-button>
          </div>
        </Container>
    </div>
</template>

<script>
import User from "./C/EditUser.vue";
import UserDetail from "./C/UserDetail.vue";
export default {
    props: {
        info: {
            type: Object,
            default() {
                return {};
            },
        },
    },
    components: {
        User,
        UserDetail,
    },
    data() {
        return {
            isLoading: false,
            isEdit: false,
            currentItem: {}, // 主申人信息（编辑&回显共用）
        };
    },
    created() {
        if (this.info.birth_place && !this.info.birth_place.details) {
            this.info.birth_place.details = ''
        }
        // 为空时，初始化相关说明字段
        const fields = ['declaration_first', 'declaration_first_desc', 'declaration_second', 'declaration_second_desc']
        fields.forEach((key) => {
            if (!this.info[key] && this.info[key] != 0) {
                this.info[key] = ''
            }
        })
        this.info.college = Number(this.info.college)
        this.currentItem = JSON.parse(JSON.stringify(this.info))
    },
    methods: {
      toDwpAddEducationCertification(){
            window.open(`${process.env.VUE_APP_SMARTER_WRITING}/online_submission/education-certification/requirement?order_id=${this.info.order_id}`)
        },
        saveInfo() {
            this.$refs.user?.checkInfo()
        },
        // 更新
        updateDetail() {
            this.currentItem = JSON.parse(JSON.stringify(this.info))
            this.isEdit = false
        },
        // 取消
        cancel() {
            this.currentItem = JSON.parse(JSON.stringify(this.info))
            this.isEdit = false
        },
    },
};
</script>

<style lang="less" scoped></style>
