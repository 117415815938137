// 获取映射
function getReflect(arr = [], valueKey = 'value', lableKey = 'name') {
    return (arr || []).reduce((obj, cur) => {
        obj[cur[valueKey]] = cur[lableKey];
        return obj;
    }, {});
}



// 性别
export const SEX_TYPE = [
    { value: 1, name: "男" },
    { value: 2, name: "女" },
]

// 性别映射
export const SEX_TYPE_REFLECT = getReflect(SEX_TYPE)

// 婚姻类型
export const MARRY_TYPE = [
    { value: 1, name: "已婚" },
    { value: 2, name: "未婚" },
    { value: 3, name: "离婚" },
    { value: 4, name: "分居" },
    { value: 5, name: "丧偶" },
]

//  婚姻映射
export const MARRY_TYPE_REFLECT = getReflect(MARRY_TYPE)

// 行业
export const INDUSTRY_TYPE = [
    { value: 1, name: "学术研究及教育" },
    { value: 2, name: "会计服务" },
    { value: 3, name: "农务" },
    { value: 4, name: "建筑及测量" },
    { value: 5, name: "艺术及文化" },
    { value: 6, name: "航空业" },
    { value: 7, name: "广播及娱乐" },
    { value: 8, name: "商业支援及人力资源" },
    { value: 9, name: "餐饮服务" },
    { value: 10, name: "商业及贸易" },
    { value: 11, name: "创意产业和表演艺术" },
    { value: 12, name: "工程及建造" },
    { value: 13, name: "环境技术服务" },
    { value: 14, name: "金融服务" },
    { value: 15, name: "政府相关" },
    { value: 16, name: "资讯及通讯科技服务" },
    { value: 17, name: "创新及科技" },
    { value: 18, name: "保险/经纪服务" },
    { value: 19, name: "法律及争议解决服务" },
    { value: 20, name: "物流及运输" },
    { value: 21, name: "工业制造" },
    { value: 22, name: "海运服务" },
    { value: 23, name: "市场营销/公共关系" },
    { value: 24, name: "医疗保健服务(包括中医及兽医)" },
    { value: 25, name: "康复及体育运动" },
    { value: 26, name: "宗教团体" },
    { value: 27, name: "旅游" },
    { value: 28, name: "其他" },
]

// 行业映射
export const INDUSTRY_TYPE_REFLECT = getReflect(INDUSTRY_TYPE)

// 证件类型
export const CERTIFICATE_TYPE = [
    {
        value: 1,
        name: '中国身份证'
    },
    {
        value: 2,
        name: '港澳通行证'
    },
    {
        value: 3,
        name: '护照'
    },
]

// 证件映射
export const CERTIFICATE_TYPE_REFLECT = getReflect(CERTIFICATE_TYPE)

// 是否
export const YES_OR_NO = [
    { value: 1, name: "是" },
    { value: 2, name: "否" },
]

// 是否映射
export const YES_OR_NO_REFLECT = getReflect(YES_OR_NO)


// 说明是否
export const STATEMENT_YES_OR_NO = [
    { value: 0, name: "是" },
    { value: 1, name: "否" },
]

export const STATEMENT_YES_OR_NO_REFLECT = getReflect(STATEMENT_YES_OR_NO)


// 说明是否
export const HIGH_CHILD_YES_OR_NO = [
    { value: 1, name: "是" },
    { value: 0, name: "否" },
]

export const HIGH_CHILD_YES_OR_NO_REFLECT = getReflect(HIGH_CHILD_YES_OR_NO)

// 国内/国外
export const ABOARD_OR_NOT = [
    { value: 0, name: "国内" },
    { value: 1, name: "国外" },
]

export const ABOARD_OR_NOT_REFLECT = getReflect(ABOARD_OR_NOT);

// 工作经验
export const EXPERIENCE_TYPE = [
    { value: 1, name: "有工作经验" },
    { value: 2, name: "无工作经验" },
]
export const EXPERIENCE_TYPE_REFLECT = getReflect(EXPERIENCE_TYPE);

// 申请类型
export const APPLY_TYPE = [
    { value: 1, name: "高才A类" },
    { value: 2, name: "高才B类" },
    { value: 3, name: "高才C类" },
]
export const APPLY_TYPE_REFLECT = getReflect(APPLY_TYPE);

// 收入类型
export const INCOMING_TYPE = [
    { value: 1, name: "工资薪金所得及全年一次性奖金" },
    { value: 2, name: "上市公司股票期权收入或股权收入" },
    { value: 3, name: "持股公司分红收入" },
    { value: 4, name: "个体工商户/合伙企业/独资企业经营所得收入" },
    { value: 5, name: "股权转让收入" },
    { value: 6, name: "内地公司收入(持股50%及以上)" },
    { value: 7, name: "香港公司收入(持股50%及以上)" },
    { value: 8, name: "其他境外公司收入(持股50%及以上)" },
    { value: 10, name: "澳门公司收入（持股50%及以上）" },
]
export const INCOMING_TYPE_REFLECT = getReflect(INCOMING_TYPE);

// 工作年限
export const WORK_YEAR_TYPE = [
    { value: 1, name: "近5年累计3年及以上工作经验" },
    { value: 2, name: "近五年毕业且累计少于3年的工作经验" },
    { value: 3, name: "近五年无工作经验" },
]
export const WORK_YEAR_TYPE_REFLECT = getReflect(WORK_YEAR_TYPE);

// 是否办理香港身份证
export const HAS_HK_CARD = [
    { value: "Y", name: "是" },
    { value: "N", name: "否" },
]
export const HAS_HK_CARD_REFLECT = getReflect(HAS_HK_CARD);

// 币种
export const CURRENCY_TYPE = [
    { "value": "澳元", "name": "澳元" },
    { "value": "加拿大元", "name": "加拿大元" },
    { "value": "瑞士法郎", "name": "瑞士法郎" },
    { "value": "人民币", "name": "人民币" },
    { "value": "欧元", "name": "欧元" },
    { "value": "英镑", "name": "英镑" },
    { "value": "HKD", "name": "HKD" },
    { "value": "印尼盾", "name": "印尼盾" },
    { "value": "印度卢比", "name": "印度卢比" },
    { "value": "日圆", "name": "日圆" },
    { "value": "南韩圆", "name": "南韩圆" },
    { "value": "马来西亚元", "name": "马来西亚元" },
    { "value": "菲律宾比索", "name": "菲律宾比索" },
    { "value": "新加坡元", "name": "新加坡元" },
    { "value": "泰铢", "name": "泰铢" },
    { "value": "新台币", "name": "新台币" },
    { "value": "美元", "name": "美元" },
    { "value": "南非兰特", "name": "南非兰特" }
  ]

// 界别
const BOUNDARY_TYPE = [
    { "value": "学术研究及教育", "name": "学术研究及教育" },
    { "value": "会计服务", "name": "会计服务" },
    { "value": "农务", "name": "农务" },
    { "value": "建筑及测量", "name": "建筑及测量" },
    { "value": "艺术及文化", "name": "艺术及文化" },
    { "value": "航空业", "name": "航空业" },
    { "value": "广播及娱乐", "name": "广播及娱乐" },
    { "value": "商业支援及人力资源", "name": "商业支援及人力资源" },
    { "value": "餐饮服务", "name": "餐饮服务" },
    { "value": "商业及贸易", "name": "商业及贸易" },
    { "value": "创意产业和表演艺术", "name": "创意产业和表演艺术" },
    { "value": "工程及建造", "name": "工程及建造" },
    { "value": "环境技术服务", "name": "环境技术服务" },
    { "value": "金融服务", "name": "金融服务" },
    { "value": "政府相关", "name": "政府相关" },
    { "value": "资讯及通讯科技服务", "name": "资讯及通讯科技服务" },
    { "value": "创新及科技", "name": "创新及科技" },
    { "value": "保险／经纪服务", "name": "保险／经纪服务" },
    { "value": "法律及争议解决服务", "name": "法律及争议解决服务" },
    { "value": "物流及运输", "name": "物流及运输" },
    { "value": "工业制造", "name": "工业制造" },
    { "value": "海运服务", "name": "海运服务" },
    { "value": "市场营销／公共关系", "name": "市场营销／公共关系" },
    { "value": "医疗保健服务（包括中医及兽医）", "name": "医疗保健服务（包括中医及兽医）" },
    { "value": "康乐及体育运动", "name": "康乐及体育运动" },
    { "value": "宗教团体", "name": "宗教团体" },
    { "value": "旅遊", "name": "旅遊" },
    { "value": "其它 （请注明）", "name": "其它 （请注明）" }
  ]

// 主修科目
const SUBJECT_TYPE = [
    { "value": "艺术与人文学科", "name": "艺术与人文学科" },
    { "value": "经济及工商管理", "name": "经济及工商管理" },
    { "value": "教育", "name": "教育" },
    { "value": "法学", "name": "法学" },
    { "value": "社会科学", "name": "社会科学" },
    { "value": "电脑科学", "name": "电脑科学" },
    { "value": "工程学", "name": "工程学" },
    { "value": "生命科学及医学", "name": "生命科学及医学" },
    { "value": "物理／自然科学", "name": "物理／自然科学" },
    { "value": "心理学", "name": "心理学" },
    { "value": "其它 （请注明）", "name": "其它 （请注明）" }
]

export default {
    SEX_TYPE,
    SEX_TYPE_REFLECT,
    MARRY_TYPE,
    MARRY_TYPE_REFLECT,
    INDUSTRY_TYPE,
    INDUSTRY_TYPE_REFLECT,
    CERTIFICATE_TYPE,
    CERTIFICATE_TYPE_REFLECT,
    YES_OR_NO,
    YES_OR_NO_REFLECT,
    STATEMENT_YES_OR_NO,
    STATEMENT_YES_OR_NO_REFLECT,
    HIGH_CHILD_YES_OR_NO,
    HIGH_CHILD_YES_OR_NO_REFLECT,
    ABOARD_OR_NOT,
    ABOARD_OR_NOT_REFLECT,
    EXPERIENCE_TYPE,
    EXPERIENCE_TYPE_REFLECT,
    APPLY_TYPE,
    APPLY_TYPE_REFLECT,
    INCOMING_TYPE,
    INCOMING_TYPE_REFLECT,
    WORK_YEAR_TYPE,
    WORK_YEAR_TYPE_REFLECT,
    HAS_HK_CARD,
    HAS_HK_CARD_REFLECT,
    CURRENCY_TYPE,
    BOUNDARY_TYPE,
    SUBJECT_TYPE,
}

