<!--
 * @Author: George
 * @Description: 高才家庭成员-子女信息
-->
<template>
    <div class="mt-20">
        <Container
            title="子女信息"
            padding="0"
            id="children-top"
            v-loading="isLoading"
        >
            <div id="children-top-name"></div>
            <div slot="handle">
                <div class="handle-btn">
                    <el-button
                        :disabled="children && children.length >= 5"
                        @click="addChild(1)"
                        >新增-随行</el-button
                    >
                    <el-button
                        :disabled="children && children.length >= 5"
                        @click="addChild(2)"
                        >新增-不随行</el-button
                    >
                </div>
            </div>
            <div v-if="children.length > 0">
                <div
                    v-for="(item, i) in children"
                    :key="item.id"
                    :id="'pdf-children' + i"
                >
                    <div
                        :class="['title-box', i > 0 ? 'mt-20' : '']"
                        :id="'children' + i"
                    >
                        <span>
                            <el-tooltip
                                v-if="item.finish_follow == 1"
                                class="item"
                                effect="dark"
                                placement="top-start"
                            >
                                <span class="tooltip">补</span>
                                <template slot="content">
                                    <div class="content">
                                        <div>
                                            创建人：
                                            {{
                                                item.supplement_info
                                                    .operator_name
                                            }}
                                        </div>
                                        <div>
                                            加入时间：
                                            {{
                                                item.supplement_info.created_at
                                            }}
                                        </div>
                                        <div>
                                            主合同任务：
                                            {{
                                                item.supplement_info
                                                    .order_task_name
                                            }}
                                        </div>
                                    </div>
                                </template>
                            </el-tooltip>
                            子女{{ i + 1 }}/{{ children.length }}
                            &nbsp;&nbsp;
                            <el-switch
                                v-if="item.id"
                                @change="(val) => followChange(val, i)"
                                active-text="随行"
                                inactive-text="不随行"
                                v-model="item.follow"
                                :active-value="1"
                                :inactive-value="2"
                            >
                            </el-switch>

                            <div
                                style="
                                    margin-left: 50px;
                                    display: inline-block;
                                    font-size: 14px;
                                "
                            >
                                是否港宝：
                                <el-switch
                                    @change="(val) => isHKKidChange(val, i)"
                                    active-text="是"
                                    inactive-text="否"
                                    v-model="item.birth_in_hk"
                                    :active-value="1"
                                    :inactive-value="0"
                                >
                                </el-switch>
                            </div>
                        </span>
                        <div class="title-btn">
                            <!-- 显示状态下按钮 -->
                            <template v-if="!item.isEdit">
                                <el-popconfirm
                                    placement="top"
                                    title="确定删除？"
                                    @confirm="del(i)"
                                >
                                    <el-button slot="reference">删除</el-button>
                                </el-popconfirm>
                                &nbsp;
                                <el-button
                                    type="primary"
                                    @click="editInfo(item)"
                                    >编辑</el-button
                                >
                            </template>
                            <!-- 编辑状态下按钮 -->
                            <template v-else>
                                <el-button
                                    :loading="item.isLoading"
                                    @click="cancel(i)"
                                    >取消</el-button
                                >
                                <el-button
                                    type="primary"
                                    :loading="item.isLoading"
                                    @click="checkInfo(i)"
                                    >保存</el-button
                                >
                            </template>
                        </div>
                    </div>
                    <!-- 显示 -->
                    <ChildrenDetail v-if="!item.isEdit" :currentItem="item" />
                    <!-- 编辑 -->
                    <EditChildren
                        :loading.sync="item.isLoading"
                        v-loading="item.isLoading"
                        :ref="'children' + i"
                        v-if="item.isEdit"
                        :required="item.follow === 1 && !item.birth_in_hk"
                        :currentItem.sync="children[i]"
                        @update="updateChildren"
                    />
                </div>
            </div>
            <div v-else class="no-data">暂无数据</div>
        </Container>
    </div>
</template>

<script>
import ChildrenDetail from "./C/ChildrenDetail.vue";
import EditChildren from "./C/EditChildren.vue";
import {
    saveHighTalentInfo,
    initHighTalentFamily,
    deleteHighTalentFamily,
} from "../../../../../../api/highTalent";

export default {
    props: {
        info: {
            type: Array,
            default: () => [],
        },
    },
    components: {
        ChildrenDetail,
        EditChildren,
    },
    data() {
        return {
            isLoading: false,
            children: [],
        };
    },
    created() {
        this.setIsEdit();
    },

    watch: {
        info: {
            handler(newVal) {
                const children = JSON.parse(JSON.stringify(newVal))
                children.forEach(item => {
                    // 为空时，初始化相关说明字段
                    const fields = ['declaration_first', 'declaration_first_desc', 'declaration_second', 'declaration_second_desc']
                    fields.forEach((key) => {
                        if (!item[key] && item[key] != 0) {
                            item[key] = ''
                        }
                    })
                })
                this.children = children
                this.setIsEdit()
            },
            deep: true,
            immediate: true,
        },
    },
    computed: {
        isEditChild() {
            return !!this.children.find((item) => item.isEdit);
        },
    },
    methods: {
        // 是否随行
        followChange(val, index) {
            if (this.isEditChild) {
                this.children[index].follow = val === 1 ? 2 : 1;
                this.$message.error("请先保存正在编辑或者新增的子女！");
                return;
            }
            this.saveInfo(
                index,
                { follow: val },
                { follow: val === 1 ? 2 : 1 }
            );
        },
        // 是否为港宝
        isHKKidChange(val, index) {
            if (this.isEditChild) {
                this.children[index].birth_in_hk = val === 0 ? 1 : 0;
                this.$message.error("请先保存正在编辑或者新增的子女！");
                return;
            }
            this.saveInfo(
                index,
                { birth_in_hk: val },
                { birth_in_hk: val === 0 ? 1 : 0 }
            );
        },
        // set isEdit
        setIsEdit() {
            this.children.forEach((item, i) => {
                this.$set(this.children[i], "isEdit", false);
                this.$set(this.children[i], "isLoading", false);
            });
        },
        // 新增 type 1 随行 2 不随行
        async addChild(type) {
            if (this.isEditChild) {
                this.$message.error("请先保存正在编辑或者新增的子女！");
                return;
            }

            this.isLoading = true;
            try {
                await initHighTalentFamily({
                    order_id: this.$route.params.id,
                    role: 2,
                    follow: type,
                });
                await this.$utils.saveHighTalentStatus(this.$route.params.id);
                this.anchorPoint();
            } finally {
                this.isLoading = false;
            }
        },

        // 随行/港宝 保存
        async saveInfo(index, params = {}, resetParams = {}) {
            try {
                this.isLoading = true;
                const parm = JSON.parse(JSON.stringify(this.info[index]));

                const { code, data } = await saveHighTalentInfo({
                    ...parm,
                    ...params,
                    role: 2,
                    crm_token: this.$route.query.crm_token,
                });
                if (code === 200) {
                    this.$message({
                        message: "保存成功",
                        type: "success",
                    });
                    await this.$utils.saveHighTalentStatus(
                        this.$route.params.id
                    );
                }
            } catch (err) {
                console.log(err);
                Object.assign(this.children[index], resetParams);
            } finally {
                this.isLoading = false;
            }
        },
        // 编辑
        editInfo(item) {
            item.isEdit = true;
        },
        // 单个子女保存
        checkInfo(index) {
            this.$refs["children" + index][0]?.checkInfo();
        },
        // 取消
        cancel(index) {
            const defaultItem = JSON.parse(JSON.stringify(this.info[index]));
            defaultItem.isEdit = false;
            defaultItem.isLoading = false;
            this.children.splice(index, 1, defaultItem);

            console.log(this.children, "2222========");
        },
        updateChildren() {
            console.log("222222");
            // this.children = JSON.parse(JSON.stringify(newVal));
            // this.setIsEdit(this.children);
        },
        // 删除
        async del(index) {
            if (this.isEditChild) {
                this.$message.error("请先保存正在编辑或者新增的子女！");
                return;
            }
            try {
                this.isLoading = true;
                const { code, data } = await deleteHighTalentFamily({
                    order_id: this.$route.params.id,
                    id: this.children[index].id,
                });
                if (code === 200) {
                    this.$message({
                        message: "删除成功",
                        type: "success",
                    });
                }
                await this.$utils.saveHighTalentStatus(this.$route.params.id);
            } finally {
                this.isLoading = false;
            }
        },
        // 新增锚点到新增相应的位置
        anchorPoint() {
            this.$nextTick(() => {
                const pageId = document.querySelector(
                    "#children" + (this.children.length - 1)
                );
                pageId.scrollIntoView({
                    block: "start",
                    behavior: "smooth",
                });
            });
        },
    },
};
</script>

<style lang="less" scoped>
.tooltip {
    display: inline-block;
    border-radius: 100%;
    background: #1296db;
    color: #fff;
    width: 20px;
    height: 20px;
    line-height: 20px;
    text-align: center;
    border: 1px #377395 solid;
    // height: 100%;
}
.content {
    padding: 3px;
    div {
        margin: 10px 0;
    }
}
</style>
