<template>
    <el-row class="hk-id-card-input">
        <el-col :span="16">
            <el-input v-model.trim="form.hk_id_number" :placeholder="placeholder" clearable auto-complete="off" :maxlength="maxlength" @input="handleInput" />
        </el-col>
        <el-col :span="8" class="suffix-input">
            <el-input v-model.trim="form.hk_id_number_suffix" :placeholder="suffixPlaceholder" clearable auto-complete="off" :maxlength="maxlength" :class="suffixRequired ? '' : 'not-required'" @input="handleInput" />
        </el-col>
    </el-row>
</template>

<script>

export default {
    props: {
        value: {
            type: String
        },
        suffixRequired: {
            type: Boolean,
            default: false
        },
        maxlength: {
            type: [Number, String],
            default: 25
        },
        placeholder: {
            type: String,
            default: ''
        },
        suffixPlaceholder: {
            type: String,
            default: ''
        }
    },
    data() {
        return {
            form: {
                hk_id_number: '',
                hk_id_number_suffix: ''
            }
        }
    },
    watch: {
        value(v1, v2) {
            v1 !== v2 && this.init()
        }
    },
    mounted() {
        this.init()
    },
    methods: {
        init() {
            const index = /[\(（]/.exec(this.value)?.index ?? -1
            if (index > -1) {
                this.form.hk_id_number = this.value.slice(0, index)
                this.form.hk_id_number_suffix = this.value.slice(index + 1, this.value.length - 1)
            } else {
                this.form.hk_id_number = this.value
            }
        },
        handleInput() {
            let result = this.form.hk_id_number
            if (this.form.hk_id_number_suffix) {
                result +=`(${this.form.hk_id_number_suffix})`
            }
            this.$emit('input', result)
        }
    }
}
</script>

<style lang="less" scoped>
.hk-id-card-input {
    display: flex;
}

.not-required /deep/ .el-input__inner {
    border-color: #DCDFE6;
    &:focus {
        border-color: #DCDFE6;
    }
}

.suffix-input {
    padding-left: 12px;
}
</style>
