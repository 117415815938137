<!--
 * @Author: filename
 * @Description: 高才个人信息表 - 资产
-->
<template>
    <div>
        <Container title="资产（在过去12个月全年收入达港币250万元或以上的记录）" padding="0" id="pdf-user-top">
            <!-- <div slot="handle">
                <div class="handle-btn">
                    <el-button @click="addRecords">增加</el-button>
                </div>
            </div> -->
            <div v-for="(item, i) in currentItem" :key="i">
                <div class="title-box" :id="`records${i}`">
                    <span>收入记录{{ `${item.company_name ? `（${item.company_name}）` : ''}` }}（{{ i + 1 }}/{{ currentItem.length }}）</span>
                    <div class="title-btn">
                        <template v-if="!item.isEdit">
                            <!-- <el-popconfirm
                                v-if="i > 0"
                                placement="top"
                                title="确定删除？"
                                @confirm="handleDel(i)"
                            >
                                <el-button style="margin-right: 10px;" slot="reference">删除</el-button>
                            </el-popconfirm> -->
                            <el-button
                                type="primary"
                                @click="item.isEdit = true"
                            >
                                编辑
                            </el-button>
                        </template>
                        <el-button
                            v-if="item.isEdit"
                            @click="handleCancel(i)"
                        >
                            取消
                        </el-button>
                        <el-button
                            v-if="item.isEdit"
                            type="primary"
                            :loading="item.isLoading"
                            @click="saveInfo(i)"
                        >
                            保存
                        </el-button>
                    </div>
                </div>
                <!-- 回显信息 -->
                <AssetsDetail
                    v-if="!item.isEdit"
                    :currentItem="item"
                />
                <!-- 编辑 -->
                <EditAsset
                    v-if="item.isEdit"
                    :ref="`assets${i}`"
                    :currentItem="item"
                    :loading.sync="item.isLoading"
                    @update="updateDetail($event, i)"
                />
            </div>
        </Container>
    </div>
</template>

<script>
import EditAsset from "./C/EditAsset.vue"
import AssetsDetail from "./C/AssetDetail.vue"
import { deleteProperty } from "../../../../api/highTalent"
import { watch } from "less";
export default {
    props: {
        info: {
            type: Object,
            default() {
                return {}
            },
        },
    },
    components: {
        EditAsset,
        AssetsDetail,
    },
    data() {
        return {
            currentItem: [], // 主申请人资产
        };
    },
    created() {
        this.currentItem = JSON.parse(JSON.stringify(this.info.propertys))
        this.setIsEdit()
    },
    watch: {
        info() {
            this.currentItem = JSON.parse(JSON.stringify(this.info.propertys))
            this.setIsEdit()
        }
    },
    computed: {
        isEditWork() {
            return !!this.currentItem.find((item) => item.isEdit)
        },
    },
    methods: {
        // set isEdit
        setIsEdit() {
            this.currentItem.forEach((item, i) => {
                this.$set(this.currentItem[i], "isEdit", false)
                this.$set(this.currentItem[i], "isLoading", false)
            })
        },
        // 保存
        saveInfo(index) {
            this.$refs[`assets${index}`][0].checkInfo()
        },
        // 更新
        updateDetail(data, index) {
            this.currentItem[index] = data
        },
        // 取消
        handleCancel(index) {
            if (this.currentItem[index].id || this.currentItem[index].id == 0) {
                this.currentItem[index].isEdit = false
            } else {
                this.currentItem.splice(index, 1)
            }
        },
        // 删除
        async handleDel(index) {
            if (this.isEditWork) {
                this.$message.error("请先保存正在编辑或者新增的收入记录！")
                return
            }
            try {
                this.isLoading = true
                if (this.currentItem[index].id) {
                    const { code } = await deleteProperty({
                        id: this.currentItem[index].id,
                    })
                    if (code === 200) {
                        this.$message({
                            message: "删除成功",
                            type: "success",
                        })
                    }
                }
                this.currentItem.splice(index, 1)

            } finally {
                this.isLoading = false
            }
        },
        // 增加收入记录
        addRecords() {
            this.currentItem.push({
                order_id: this.$route.params.id,
                currency: '',
                amount_of_money: '',
                isEdit: true,
                isLoading: false,
            })
            this.anchorPoint()
        },
        // 新增锚点到新增相应的位置
        anchorPoint() {
            this.$nextTick(() => {
                const pageId = document.querySelector(
                    "#records" + (this.currentItem.length - 1)
                );
                pageId.scrollIntoView({
                    block: "start",
                    behavior: "smooth",
                })
            })
        },
    },
};
</script>

<style lang="less" scoped>

</style>
