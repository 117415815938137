<!--
 * @Author: George
 * @Description: 高才-家庭成员信息
-->

<template>
    <div v-if="info">
        <!-- 配偶 -->
        <Spouse v-if="showSpouse" :info="data.spouse" />
        <!-- 子女 -->
        <Children v-if="data.children" :info="data.children" />
    </div>
</template>

<script>
import Spouse from "./C/Spouse/Spouse.vue";
import Children from "./C/Children/Children.vue";
export default {
    props: {
        info: {
            type: Object,
            default: () => {},
        },
    },
    components: {
        Spouse,
        Children,
    },
    watch: {
        info: {
            handler(newVal) {
                this.data = JSON.parse(JSON.stringify(newVal));
            },
            deep: true,
            immediate: true,
        },
    },
    computed: {
        showSpouse() {
            return ![2, 3, 5].includes(this.data?.applicant?.married);
        },
    },
    data() {
        return {
            data: {},
        };
    },
};
</script>

<style lang="less" scoped></style>
